import { FC } from 'react'
import { HOME_ROUTE, LOGIN_ROUTE } from './utils/routenames'
import { Login } from './pages/Login'
import { Home } from './pages/Home'

interface Route {
	path: string
	Element: FC
}

export const loginRoutes: Route[] = [
	{ path: LOGIN_ROUTE, Element: Login },
	{ path: '*', Element: Login }
]
export const authRoutes: Route[] = [
	{ path: HOME_ROUTE, Element: Home },
	{ path: '*', Element: Home }
]
