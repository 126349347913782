import axios from 'axios'
import { create } from 'zustand'

type AuthStore = {
	isLoggedIn: boolean
	user: string[] | null
	setUser: (token: string | null) => void
	login: (values: { login: string; password: string }) => void
	logout: () => void
}

export const useAuthStore = create<AuthStore>((set) => ({
	isLoggedIn: false,
	user: null,
	setUser: (token: string | null) => {
		if (!token) {
			return set({ isLoggedIn: false, user: null })
		}
		const user = token && (atob(token) as any).split(':')
		set({ isLoggedIn: true, user })
	},
	login: async (values) => {
		try {
			const { data } = await axios.post(
				`${process.env.REACT_APP_API_URL}/api/auth/signin`,
				values
			)
			localStorage.setItem('token', data.token)
			const user =
				data.token && (atob(data.token) as any).split(':')
			set({ isLoggedIn: true, user })
		} catch (error: any) {
			set({ isLoggedIn: false, user: null })
			alert(error.response.data.message)
		}
	},
	logout: () => {
		localStorage.removeItem('token')
		set({ isLoggedIn: false, user: null })
	}
}))
