import { useEffect } from 'react'
import './App.scss'
import { AppRouter } from './components'
import { useAuthStore } from './store/auth'
import { useSensorStore } from './store/sensorData'

function App() {
	const { isLoggedIn, setUser } = useAuthStore()
	const { getHourlyData } = useSensorStore()

	useEffect(() => {
		const token = localStorage.getItem('token')
		setUser(token)
	}, [setUser])

	useEffect(() => {
		if (!isLoggedIn) return

		async function fetchData() {
			await getHourlyData(new Date())
		}
		fetchData()
	}, [isLoggedIn, getHourlyData])

	return <AppRouter isLoggedIn={isLoggedIn} />
}

export default App
