import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { HOME_ROUTE } from '../utils/routenames'
import { useAuthStore } from '../store/auth'

export const Login: FC = () => {
	const navigate = useNavigate()
	const { login } = useAuthStore()
	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		login(values)
		navigate(HOME_ROUTE)
	}

	const [values, setValues] = useState({
		login: '',
		password: ''
	})
	return (
		<form onSubmit={onSubmit} className="login">
			<img src="/apple-icon-180x180.png" alt="logo" />
			<h1>Tizimga kirish</h1>
			<input
				type="text"
				placeholder="login"
				value={values.login}
				onChange={(e) =>
					setValues({ ...values, login: e.target.value })
				}
			/>
			<input
				type="password"
				placeholder="password"
				value={values.password}
				onChange={(e) =>
					setValues({ ...values, password: e.target.value })
				}
			/>
			<select>
				<option>Ma'lumotlar bazasi 1</option>
				<option>Ma'lumotlar bazasi 2</option>
			</select>
			<button>Kirish</button>
		</form>
	)
}
