import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Calendar from 'react-calendar'
import { Value } from '../pages/Home'
import { useAuthStore } from '../store/auth'

interface SideBarProps {
	date: Value
	setDate: (date: Value) => void
}

export const SideBar: FC<SideBarProps> = ({ date, setDate }) => {
	const { search } = useLocation()
	const { logout } = useAuthStore()

	return (
		<div className="sidebar">
			<header className="sidebar__header">
				<img src="/apple-icon-120x120.png" alt="logo" />
				<h1>IoT 1-signal nuqtasi arxivi</h1>
			</header>
			<ul className="sidebar__menu">
				<li>
					<Link
						to="/"
						className={search === '' ? 'active' : ''}>
						Rele kuchlanishlar
					</Link>
				</li>
				<li>
					<Link
						to="?supply"
						className={
							search === '?supply' ? 'active' : ''
						}>
						Asosiy va zaxira kuchlanishlar
					</Link>
				</li>
				<li>
					<Link
						to="?temperature"
						className={
							search === '?temperature' ? 'active' : ''
						}>
						Harorat
					</Link>
				</li>
			</ul>
			<div className="sidebar__calendar">
				<Calendar
					onChange={setDate}
					value={date}
					maxDate={new Date()}
					minDate={
						new Date(
							new Date().setDate(
								new Date().getDate() - 31
							)
						)
					}
				/>
			</div>
			<div className="sidebar__footer">
				<p>
					© {new Date().getFullYear()} IoT 1-signal nuqtasi
					arxivi
				</p>
			</div>
			<div className="sidebar__logout last">
				<button onClick={logout}>Chiqish</button>
			</div>
		</div>
	)
}
