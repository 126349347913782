import { FC, useEffect, useState } from 'react'
import { LineChart } from '../components/LineChart'
import { useSensorStore } from '../store/sensorData'
import 'react-calendar/dist/Calendar.css'
import { SideBar } from '../components/SideBar'
import { useLocation } from 'react-router'

type ValuePiece = Date | null
export type Value = ValuePiece | [ValuePiece, ValuePiece]

export const Home: FC = () => {
	const { hourlyData, getHourlyData } = useSensorStore()
	const [date, setDate] = useState<Value>(
		new Date()
	)

	const formattedDate = (date: Date | Value): string => {
		const dateObj = new Date(date as Date)
		const year = dateObj.getFullYear()
		const month = String(dateObj.getMonth() + 1).padStart(2, '0')
		const day = String(dateObj.getDate()).padStart(2, '0')

		return `${year}-${month}-${day}`
	}

	useEffect(() => {
		const fetchData = async () => {
			await getHourlyData(date as Date)
		}
		fetchData()
	}, [date, getHourlyData])

	const { search } = useLocation()

	return (
		<div
			style={{
				display: 'flex'
			}}>
			<SideBar date={date} setDate={setDate} />
			<div className="content">
				<header className="content__header">
					<h1>
						{formattedDate(date)} kuni uchun ma'lumotlar
					</h1>
				</header>
				<main className="content__main">
					<fieldset>
						<LineChart
							datasets={[
								{
									data: hourlyData[
										formattedDate(date) as string
									]?.map(
										(data) =>
											data[
												search === '?supply'
													? 'a1'
													: search ===
													  '?temperature'
													? 'temp'
													: 'a0'
											]
									),
									name:
										search === '?supply'
											? 'Asosiy kuchlanish'
											: search ===
											  '?temperature'
											? 'Harorat'
											: 'IVG1 rele kuchlanishi',
									createdAt: hourlyData[
										formattedDate(date) as string
									]?.map((data) => data.createdAt)
								},
								{
									data: hourlyData[
										formattedDate(date) as string
									]?.map(
										(data) =>
											data[
												search === '?supply'
													? 'a2'
													: search ===
													  '?temperature'
													? 'temp'
													: 'a3'
											]
									),
									name:
										search === '?supply'
											? 'Zaxira kuchlanish'
											: search ===
											  '?temperature'
											? 'Harorat'
											: 'IVG2 rele kuchlanishi',
									createdAt: hourlyData[
										formattedDate(date) as string
									]?.map((data) => data.createdAt)
								}
							].filter(
								(_, index) =>
									search !== '?temperature' ||
									index !== 1
							)}
						/>
					</fieldset>
				</main>
			</div>
		</div>
	)
}
