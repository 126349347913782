import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { authRoutes, loginRoutes } from '../routes'
import { HOME_ROUTE, LOGIN_ROUTE } from '../utils/routenames'

interface AppRouterProps {
	isLoggedIn: boolean
}

export const AppRouter: FC<AppRouterProps> = ({ isLoggedIn }) => {
	return (
		<Routes>
			{isLoggedIn
				? authRoutes.map(({ path, Element }) => {
						if (path === '*') {
							return (
								<Route
									key={'*'}
									path={'*'}
									element={
										<Navigate to={HOME_ROUTE} />
									}
								/>
							)
						} else {
							if (path === '*') {
								return (
									<Route
										key={'*'}
										path={'*'}
										element={
											<Navigate
												to={LOGIN_ROUTE}
											/>
										}
									/>
								)
							} else {
								return (
									<Route
										key={path}
										path={path}
										element={<Element />}
									/>
								)
							}
						}
				  })
				: loginRoutes.map(({ path, Element }) => (
						<Route
							key={path}
							path={path}
							element={<Element />}
						/>
				  ))}
		</Routes>
	)
}
