import React, { FC } from 'react'
import {
	Chart as ChartJS,
	Tooltip,
	Legend,
	CategoryScale,
	LineElement,
	LinearScale,
	PointElement,
	Title
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
)

interface LineChartProps {
	datasets: {
		name: string
		data: number[]
		createdAt: string[]
	}[]
}

export const LineChart: FC<LineChartProps> = ({ datasets }) => {
	const labels = [
		'00:00',
		'00:30',
		'01:00',
		'01:30',
		'02:00',
		'02:30',
		'03:00',
		'03:30',
		'04:00',
		'04:30',
		'05:00',
		'05:30',
		'06:00',
		'06:30',
		'07:00',
		'07:30',
		'08:00',
		'08:30',
		'09:00',
		'09:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:30',
		'20:00',
		'20:30',
		'21:00',
		'21:30',
		'22:00',
		'22:30',
		'23:00',
		'23:30'
	]

	const uniqueData = (
		data: number[],
		createdAt: string[]
	): Array<number | null> => {
		// createdAt: 2024-03-13T19:00:04.548Z
		if (!data || data.length === 0) return Array(48).fill(null)
		if (data.length === 48) return data
		return labels.map((label) => {
			const index = createdAt.findIndex((date) => {
				const dateObj = new Date(date)
				const hours = String(dateObj.getHours()).padStart(
					2,
					'0'
				)
				const minutes =
					String(dateObj.getMinutes())
						.padStart(2, '0')
						.substring(0, 1) === '0'
						? '00'
						: '30'
				return label === `${hours}:${minutes}`
			})
			return index === -1 ? null : data[index]
		})
	}

	return (
		<Line
			data={{
				labels,
				datasets: datasets.map(
					({ name, data, createdAt }, index) => {
						return {
							label: name,
							backgroundColor: [
								index % 2
									? 'rgba(255, 99, 132, 0.2)'
									: 'rgba(54, 162, 235, 0.2)'
							],
							borderColor: [
								index % 2
									? 'rgba(255, 99, 132, 1)'
									: 'rgba(54, 162, 235, 1)'
							],
							data: uniqueData(data, createdAt),
							borderWidth: 3,
							borderJoinStyle: 'round',
							borderCapStyle: 'round',
							pointRadius: 5,
							pointHoverRadius: 10,
							pointHoverBorderWidth: 6
						}
					}
				)
			}}
			options={{
				spanGaps: true,
				plugins: {
					legend: {
						labels: {
							font: {
								size: 20,
								weight: 'bold'
							}
						}
					}
				}
			}}
		/>
	)
}
