import axios from 'axios'
import { create } from 'zustand'

type SensorStore = {
	hourlyData: {
		[date: string]: {
			a0: number
			a1: number
			a2: number
			a3: number
			temp: number
			createdAt: string
		}[]
	}
	getHourlyData: (date: Date) => Promise<void>
}

export const useSensorStore = create<SensorStore>((set) => ({
	hourlyData: {},
	getHourlyData: async (date: Date) => {
		const dateObj = new Date(date as Date)
		const year = dateObj.getFullYear()
		const month = String(dateObj.getMonth() + 1).padStart(2, '0')
		const day = String(dateObj.getDate()).padStart(2, '0')

		const subDate = `${year}-${month}-${day}`
		if (
			useSensorStore
				.getState()
				.hourlyData.hasOwnProperty(subDate)
		)
			return
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/sensorsdata/${subDate}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem(
						'token'
					)}`
				}
			}
		)

		set((state) => {
			return (state.hourlyData[subDate] = data)
		})
	}
}))
